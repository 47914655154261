export const ENRICHMENTS_INDICATOR = '!';

export enum ValueType {
	title = 'title',
	body = 'body',
	description = 'description',
	mainImageURL = 'mainImageURL',
	iconImageURL = 'iconImageURL',
	sponsorTitle = 'sponsorTitle',
	callToAction = 'callToAction',
	advertisingText = 'advertisingText',
	imageIdx = 'imageIdx',
	bodyIdx = 'bodyIdx',
	callToActionIdx = 'callToActionIdx',
	clickUrl = 'clickUrl',
	privacyLink = 'privacyLink',
	displayUrlIdx = 'displayUrlIdx',
	video = 'video',
	image1 = 'image1',
	image2 = 'image2',
	image3 = 'image3',
	image4 = 'image4',
	image5 = 'image5',
	body1 = 'body1',
	body2 = 'body2',
	body3 = 'body3',
	body4 = 'body4',
	body5 = 'body5',
	callToAction1 = 'callToAction1',
	callToAction2 = 'callToAction2',
	callToAction3 = 'callToAction3',
	callToAction4 = 'callToAction4',
	callToAction5 = 'callToAction5',
	displayUrl1 = 'displayUrl1',
	displayUrl2 = 'displayUrl2',
	displayUrl3 = 'displayUrl3',
	displayUrl4 = 'displayUrl4',
	displayUrl5 = 'displayUrl5',
	none = 'none'
}

export interface XandrNativeAdObjectConfig {
	title?: string
	body?: string
	desc2?: string
	image?: {
		url: string
	}
	icon?: {
		url: string
	}
	sponsoredBy?: string
	cta?: string
	clickUrl?: string
	privacyLink?: string

	video?: {
		content: string
	}
	address?: string

	[key: `customBody${number}`]: string

	[key: `customImage${number}`]: {
		url: string
	}

	[key: `customCta${number}`]: string

	[key: `customDisplayUrl${number}`]: string

	valueTypeIndex?: number
	leadgen?: LeadformAdObjectEnrichments
	clickTrackers?: string[]
}

export default class NativeAdObject {
	title?: string;
	body?: string;
	description?: string;
	imageUrl?: string;
	iconUrl?: string;
	sponsoredBy?: string;
	callToAction?: string;
	clickUrl?: string;
	privacyLink?: string;

	video?: string;
	leadgen?: LeadformAdObjectEnrichments;

	[key: `customBody${number}`]: string

	[key: `customImageUrl${number}`]: string

	[key: `customCta${number}`]: string

	[key: `customDisplayUrl${number}`]: string

	valueTypeIndex?: number;
	valueTypeMaxIdx: number;

	callToActionDefaultText?: string;
	callToActionCharacterFilter?: string;

	clickTrackers?: string[];

	constructor(config: XandrNativeAdObjectConfig, renderConfig: RenderConfig = {}) {
		this.title = config.title;
		this.body = config.body;
		this.description = config.desc2;
		this.callToAction = config.cta;
		this.sponsoredBy = config.sponsoredBy;
		this.clickUrl = config.clickUrl;
		this.imageUrl = config.image?.url;
		this.iconUrl = config.icon?.url;
		this.video = config.video?.content;
		this.privacyLink = config.privacyLink;

		for (let i = 1; i <= 5; i++) {
			this[`customBody${i}`] = config[`customBody${i}`];
			this[`customImageUrl${i}`] = config[`customImage${i}`]?.url;
			this[`customCta${i}`] = config[`customCta${i}`];
			this[`customDisplayUrl${i}`] = config[`customDisplayUrl${i}`];
		}

		this.valueTypeIndex = config.valueTypeIndex;
		this.valueTypeMaxIdx = this.#getCustomsCount();

		this.callToActionDefaultText = renderConfig.callToActionDefaultText;
		this.callToActionCharacterFilter = renderConfig.callToActionCharacterFilter;

		if (config?.leadgen) {
			this.leadgen = config.leadgen as LeadformAdObjectEnrichments;
		}

		this.clickTrackers = config.clickTrackers;
	}

	static async build(config: XandrNativeAdObjectConfig, renderConfig: RenderConfig = {}): Promise<NativeAdObject> {
		let adObject = new NativeAdObject(config, renderConfig);
		const creativeId = renderConfig.context?.slot?.adResponse?.creativeId;

		if (config.address?.endsWith(ENRICHMENTS_INDICATOR) && creativeId) {
			try {
				const url = `https://advertising-cdn.dpgmedia.cloud/creative-enrichments/${creativeId}.json`,
					response = await fetch(url),
					decoded: AdObjectEnrichments = await response.json();

				adObject = Object.assign(adObject, decoded ?? {});
			} catch (e) {
				console.warn('[ADVERT] Something went wrong fetching the creative enrichments | Creative id:', creativeId, '| Error:', e);
			}
		}

		return adObject;
	}

	getValue(valueType: ValueType, customValueTypeIndex?: number): string {
		switch (valueType) {
			case ValueType.title:
				return this.title ?? '';

			case ValueType.body:
				return this.body ?? '';

			case ValueType.description:
				return this.description ?? '';

			case ValueType.mainImageURL:
				return this.imageUrl ?? '';

			case ValueType.iconImageURL:
				return this.iconUrl ?? '';

			case ValueType.sponsorTitle:
				return this.sponsoredBy ?? '';

			case ValueType.callToAction:
				return this.#getCTA(this.callToAction);

			case ValueType.clickUrl:
				return this.clickUrl ?? '';

			case ValueType.privacyLink:
				return this.privacyLink ?? '';

			case ValueType.video:
				return this.video ?? '';

			case ValueType.bodyIdx:
				return this[`customBody${this.#getValueTypeIndex(customValueTypeIndex)}`] ?? '';

			case ValueType.imageIdx:
				return this[`customImageUrl${this.#getValueTypeIndex(customValueTypeIndex)}`] ?? '';

			case ValueType.callToActionIdx:
				return this.#getCTA(this[`customCta${this.#getValueTypeIndex(customValueTypeIndex)}`]);

			case ValueType.displayUrlIdx:
				return this[`customDisplayUrl${this.#getValueTypeIndex(customValueTypeIndex)}`] ?? '';

			case ValueType.image1:
			case ValueType.image2:
			case ValueType.image3:
			case ValueType.image4:
			case ValueType.image5:
				return this[`customImageUrl${this.#getIndexFromValueType(valueType)}`] ?? '';

			case ValueType.body1:
			case ValueType.body2:
			case ValueType.body3:
			case ValueType.body4:
			case ValueType.body5:
				return this[`customBody${this.#getIndexFromValueType(valueType)}`] ?? '';

			case ValueType.callToAction1:
			case ValueType.callToAction2:
			case ValueType.callToAction3:
			case ValueType.callToAction4:
			case ValueType.callToAction5:
				return this[`customCta${this.#getIndexFromValueType(valueType)}`] ?? '';

			case ValueType.displayUrl1:
			case ValueType.displayUrl2:
			case ValueType.displayUrl3:
			case ValueType.displayUrl4:
			case ValueType.displayUrl5:
				return this[`customDisplayUrl${this.#getIndexFromValueType(valueType)}`] ?? '';

			case valueType.match(/^leadgen\./)?.input:
				// @ts-ignore
				return this.leadgen?.data?.[valueType.split('.')[1]] ?? '';

			case ValueType.advertisingText:
			case ValueType.none:
			default:
				return '';
		}
	}

	#getValueTypeIndex(customValueTypeIndex?: number) {
		return (customValueTypeIndex ?? this.valueTypeIndex ?? 0) + 1;
	}

	#getIndexFromValueType(valueType: ValueType) {
		return parseInt(valueType.charAt(valueType.length - 1), 10);
	}

	#getCTA(ctaText?: string): string {
		const text = ctaText ?? this.callToActionDefaultText ?? '';

		if (!this.callToActionCharacterFilter) {
			return text;
		}

		return text.replace(new RegExp(this.callToActionCharacterFilter, 'g'), '');
	}

	#getCustomsCount(): number {
		return Object.keys(this)
			.filter((s) => s.startsWith('custom'))
			.filter((s: `customBody${number}` | `customCta${number}` | `customImageUrl${number}` | `customDisplayUrl${number}`) => typeof this[s] !== 'undefined')
			.reduce((hIdx, key) => {
				const keyIdx = parseInt(key.replace(/[^\d]/g, ''), 10) - 1;

				return hIdx < keyIdx ? keyIdx : hIdx;
			}, -1);
	}
}
