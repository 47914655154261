import {DEFAULT_COLORS, getBorderString, getColorString, getFontFamily, getSpacingString} from '../../util/style-util';
import BaseComponent, {BaseComponentConfig} from '../BaseComponent';
import {findInTree} from '../../util/component-util';
import ValidationLabel from '../ValidationLabel';
import {CSSInterpolation} from '@emotion/css/create-instance';
import {validateInput} from '../../../util/inputValidation';
import {Component} from '../index';

export type LeadformInputConfig = {
	type: 'leadformInput'
	fieldName: string
	font?: string
	fontWeb?: string
	fontSize?: number
	fontSizeWeb?: number
	fontWeight?: number
	fontWeightWeb?: number
	color?: Color
	backgroundColor?: Color
	margin?: Spacing
	padding?: Spacing
	alignment?: string
	size?: Size,
	cornerRadius?: number
	borderWidth?: number
	borderColor?: Color
	placeholder?: string
	placeholderColor?: Color
	inputType?: string
	lineCount?: number
	validation?: Validation
	onFocus?: {
		borderColor: Color
	}
	onError?: {
		borderColor?: Color
		color?: Color
	}
} & BaseComponentConfig;

export default class LeadformInput extends BaseComponent {
	type: 'leadformInput';
	fieldName: string;
	font?: string;
	fontSize?: number;
	fontWeight?: number;
	color?: Color;
	backgroundColor?: Color;
	margin?: Spacing;
	padding?: Spacing;
	textAlign?: string;
	width?: number;
	height?: number;
	cornerRadius?: number;
	borderWidth?: number;
	borderColor?: Color;
	placeholder?: string;
	placeholderColor?: Color;
	inputType?: string;
	lineCount?: number;
	validation?: Validation;
	onFocus?: {
		borderColor?: Color
	};
	onError?: {
		borderColor?: Color
		color?: Color
	};
	element: HTMLInputElement | HTMLTextAreaElement;
	validationLabel: ValidationLabel | null;

	constructor(config: LeadformInputConfig) {
		super(config);

		this.margin = config.margin;
		this.padding = config.padding;
		this.color = config.color;
		this.fontSize = config.fontSizeWeb ?? config.fontSize;
		this.fontWeight = config.fontWeightWeb ?? config.fontWeight;
		this.font = config.fontWeb ?? config.font;
		this.backgroundColor = config.backgroundColor;
		this.borderWidth = config.borderWidth;
		this.borderColor = config.borderColor;
		this.cornerRadius = config.cornerRadius;
		this.placeholder = config.placeholder;
		this.placeholderColor = config.placeholderColor;
		this.inputType = config.inputType;
		this.fieldName = config.fieldName;
		this.textAlign = config.alignment;
		this.width = config.size?.width;
		this.height = config.size?.height;
		this.lineCount = config.lineCount;
		this.validation = config.validation;
		this.onFocus = config.onFocus;
		this.onError = config.onError;
	}

	render(renderContext: RenderContext): HTMLElement {
		let el;

		if (this.lineCount) {
			el = document.createElement('textarea');
			el.setAttribute('rows', String(this.lineCount));
		} else {
			el = document.createElement('input');
		}

		this.element = el;

		el.setAttribute('type', 'text');
		el.setAttribute('name', this.fieldName);
		el.setAttribute('inputType', this.inputType ?? 'text');

		if (this.placeholder) {
			el.setAttribute('placeholder', this.placeholder);
		}

		renderContext.style.style(el, {
			'pointerEvents': 'all',
			'margin': getSpacingString(this.margin),
			'padding': getSpacingString(this.padding),
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'color': getColorString(renderContext, this.color) ?? DEFAULT_COLORS.BLACK,
			'fontFamily': getFontFamily(this.font),
			'fontWeight': `${this.fontWeight ?? 'normal'}`,
			'fontSize': `${this.fontSize}px`,
			'border': typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined' ? getBorderString(renderContext, {
				'width': this.borderWidth,
				'color': this.borderColor
			}) : 'none'
		});

		if (typeof this.width === 'number') {
			renderContext.style.style(el, {
				'width': `${this.width}px`
			});
		}

		if (typeof this.height === 'number') {
			renderContext.style.style(el, {
				'height': `${this.height}px`
			});
		}

		if (this.textAlign) {
			// @ts-ignore: TextAlign != string
			renderContext.style.style(el, {
				'textAlign': this.textAlign
			});
		}

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(el, {
				'borderRadius': `${this.cornerRadius}px`
			});
		}

		if (this.placeholderColor) {
			renderContext.style.style(el, {
				'::placeholder': {
					'color': getColorString(renderContext, this.placeholderColor) ?? DEFAULT_COLORS.BLACK
				}
			});
		}

		if (this.onFocus && typeof this.onFocus.borderColor !== 'undefined') {
			renderContext.style.style(el, {
				'&:focus-visible': {
					'outline': 'none'
				},
				'&:focus': {
					'border': getBorderString(renderContext, {
						'width': this.borderWidth,
						'color': this.onFocus.borderColor
					})
				}
			});
		}

		if (this.onError) {
			const errorStyles: CSSInterpolation = {};

			if (typeof this.onError.borderColor !== 'undefined') {
				errorStyles.border = getBorderString(renderContext, {
					'width': this.borderWidth,
					'color': this.onError.borderColor
				});
			}

			if (typeof this.onError.color !== 'undefined') {
				errorStyles.color = getColorString(renderContext, this.onError.color) ?? DEFAULT_COLORS.BLACK;
			}

			renderContext.style.style(el, {
				'&[state="error"]': errorStyles
			});
		}

		if (this.validation) {
			this.#addValidation(renderContext.componentTree);
		}

		return el;
	}

	getValue(): string {
		return this.element.value;
	}

	validate(): boolean {
		return validateInput(this.element, this.validation as Validation, this.validationLabel as ValidationLabel);
	}

	#addValidation(componentTree: Component): void {
		this.validationLabel = findInTree<ValidationLabel>(componentTree, (c: ValidationLabel) => c.type === 'validationLabel' && c.errorId === this.validation?.id);
	}
}
