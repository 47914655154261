import renderTemplate from './renderer';
import {ENRICHMENTS_INDICATOR, XandrNativeAdObjectConfig} from './renderer/model/NativeAdObject';
import {getTemplateNameFromProduct} from './util/product-util';

declare global {
	interface Window {
		advert: {
			nativeTemplates: {
				render: typeof render
				templates?: Array<NativeTemplate>
				products?: Record<string, NativeTemplateProduct>
				renderConfig?: RenderConfig
			}
		}
	}
}

window.advert = window.advert || {};
window.advert.nativeTemplates = window.advert.nativeTemplates || {};
window.advert.nativeTemplates.render = render;

function render(adObjectCfg: XandrNativeAdObjectConfig, nativeTemplate: NativeTemplate | NativeTemplateProduct | string, renderCfg?: RenderConfig, containerEl?: HTMLElement) {
	try {
		const renderConfig = renderCfg ?? window.advert.nativeTemplates.renderConfig ?? {},
			template: NativeTemplate | null = getTemplate(nativeTemplate, renderConfig, containerEl);

		if (template === null) {
			throw new Error('Could not find native template');
		}

		const component = renderTemplate(adObjectCfg, template, renderConfig);

		if (containerEl) {
			containerEl.appendChild(component);
		}

		return component;
	} catch (e) {
		console.error('[ADVERT] Something went wrong rendering a native template | Template:', nativeTemplate, '| Error:', e);

		throw e;
	}
}

export function getTemplate(nativeTemplate: NativeTemplate | NativeTemplateProduct | string, renderConfig: RenderConfig, containerEl?: HTMLElement): NativeTemplate | null {
	const spec = _getSpec(nativeTemplate);

	if (_isProduct(spec)) {
		return _getTemplateFromName(getTemplateNameFromProduct(spec, renderConfig, containerEl));
	}

	return spec;
}


function _getSpec(nativeTemplate: NativeTemplate | NativeTemplateProduct | string): NativeTemplate | NativeTemplateProduct | null {
	if (typeof nativeTemplate === 'string') {
		return _getProductFromName(nativeTemplate) ?? _getTemplateFromName(nativeTemplate);
	}

	return nativeTemplate;
}

function _isProduct(spec: NativeTemplate | NativeTemplateProduct | null): spec is NativeTemplateProduct {
	return spec !== null && 'templates' in spec;
}

function _getTemplateFromName(templateName: string | null): NativeTemplate | null {
	return window.advert.nativeTemplates.templates?.find(({name}) => name === templateName) ?? null;
}

function _getProductFromName(templateName: string): NativeTemplateProduct | null {
	const productName = templateName.endsWith(ENRICHMENTS_INDICATOR) ? templateName.slice(0, -ENRICHMENTS_INDICATOR.length) : templateName;

	return window.advert.nativeTemplates.products?.[productName] ?? null;
}
