import {loadLibrary, waitForDOMAttachment} from './document-util';

type IMAManagerConfig = {
	container: HTMLElement
	content: string
	autoPlay?: boolean
	startMuted?: boolean
	onReady?: () => void
	onComplete?: () => void
	onStart?: () => void
	onStop?: () => void
	onError?: () => void
};

const IMA_SDK_URL = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';

export default class IMAManager {
	readonly #container: HTMLElement;
	readonly #content: string;
	readonly #startMuted: boolean;
	readonly #autoPlay: boolean;
	readonly #onReady?: () => void;
	readonly #onComplete?: () => void;
	readonly #onStart?: () => void;
	readonly #onStop?: () => void;
	readonly #onError?: () => void;

	#adDisplayContainer: google.ima.AdDisplayContainer;
	#adsLoader: google.ima.AdsLoader;

	adsManager: google.ima.AdsManager;

	isPaused: boolean;
	adStarted: boolean;

	constructor(config: IMAManagerConfig, renderContext: RenderContext) {
		this.isPaused = false;
		this.adStarted = false;

		this.#container = config.container;
		this.#content = config.content;
		this.#autoPlay = config.autoPlay ?? true;
		this.#startMuted = config.startMuted ?? true;
		this.#onReady = config.onReady;
		this.#onComplete = config.onComplete;
		this.#onStart = config.onStart;
		this.#onStop = config.onStop;
		this.#onError = config.onError;

		const imaContainer = document.createElement('div');

		renderContext.style.style(imaContainer, {
			position: 'absolute',
			top: '0px',
			left: '0px',
			width: '100%',
			height: '100%',
			background: 'transparent',
			zIndex: '1',
			overflow: 'hidden',
			pointerEvents: 'none'
		});

		this.#container.appendChild(imaContainer);

		Promise.all([
			waitForDOMAttachment(this.#container, renderContext.shadowRoot),
			loadLibrary(IMA_SDK_URL, () => typeof window.google === 'object' && typeof window.google.ima === 'object'),
		]).then(() => {
			this.#init(imaContainer);
		});
	}

	#init(imaContainer: HTMLElement): void {
		this.#adDisplayContainer = new google.ima.AdDisplayContainer(imaContainer);

		this.#adsLoader = new google.ima.AdsLoader(this.#adDisplayContainer);

		this.#adsLoader.addEventListener(
			google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
			e => {
				this.#onAdsManagerLoaded(e);
			},
			false
		);

		this.#adsLoader.addEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			(err) => {
				console.error('[ADVERT] Native Renderer: Something went wrong in the video ad response', err);
				this.#onError?.();
			},
			false
		);

		this.#adsLoader.requestAds(this.#createAdRequest());
	}

	reset() {
		this.isPaused = false;
		this.adStarted = false;

		this.#adsLoader.requestAds(this.#createAdRequest());
	}

	resize(width: number, height: number): void {
		this.adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
	}

	setVolume(volume: number): void {
		this.adsManager?.setVolume(volume);
	}

	play(muted: boolean = this.#startMuted): void {
		if (!this.adStarted) {
			this.#startPlaying(muted);

			return;
		}

		if (this.isPaused) {
			this.adsManager.resume();
		}
	}

	pause(): void {
		if (!this.adStarted || this.isPaused) {
			return;
		}

		this.adsManager.pause();
	}

	#createAdRequest(): google.ima.AdsRequest {
		const adsRequest = new google.ima.AdsRequest();

		adsRequest.adTagUrl = '';
		adsRequest.adsResponse = this.#content;

		adsRequest.setAdWillAutoPlay(this.#autoPlay);
		adsRequest.setAdWillPlayMuted(this.#startMuted);

		adsRequest.linearAdSlotWidth = this.#container.clientWidth;
		adsRequest.linearAdSlotHeight = this.#container.clientHeight;

		return adsRequest;
	}

	#startPlaying(muted: boolean) {
		this.#adDisplayContainer.initialize();

		this.adsManager.init(this.#container.clientWidth, this.#container.clientHeight, google.ima.ViewMode.NORMAL);
		this.adsManager.setVolume(muted ? 0 : 1);
		this.adsManager.start();
	}

	#onAdsManagerLoaded(adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent): void {
		const renderSettings = new google.ima.AdsRenderingSettings();

		// Remove AD_ATTRIBUTION and COUNTDOWN
		renderSettings.uiElements = [];

		this.adsManager = adsManagerLoadedEvent.getAdsManager({
			currentTime: 0,
			duration: 0
		}, renderSettings);

		this.adsManager.addEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			(err) => {
				console.error('[ADVERT] Native Renderer: Something went wrong in the video ad', err);
				this.#onError?.();
			}
		);

		this.adsManager.addEventListener(
			google.ima.AdEvent.Type.STARTED,
			() => {
				this.adStarted = true;
				this.isPaused = false;

				this.#onStart?.();
			}
		);

		this.adsManager.addEventListener(
			google.ima.AdEvent.Type.PAUSED,
			() => {
				this.isPaused = true;
				this.#onStop?.();
			}
		);

		this.adsManager.addEventListener(
			google.ima.AdEvent.Type.RESUMED,
			() => {
				this.isPaused = false;
				this.#onStart?.();
			}
		);

		this.adsManager.addEventListener(
			google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
			() => {
				this.#onComplete?.();
			}
		);

		this.#onReady?.();
	}

	getRemainingTime(): number {
		return this.adsManager?.getRemainingTime() ?? -1;
	}

	getVideoSize(): { width: number, height: number } {
		const currentAd = this.adsManager.getCurrentAd();

		return {
			width: currentAd?.getVastMediaWidth() ?? 0,
			height: currentAd?.getVastMediaHeight() ?? 0
		};
	}
}
