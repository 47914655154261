import BaseComponent from './BaseComponent';

export default class Unknown extends BaseComponent {
	constructor(config: unknown) {
		super({'type': 'unknown'});

		console.warn('[ADVERT] Native Renderer: Trying to render unknown component', config);
	}

	render(): DocumentFragment {
		return document.createDocumentFragment();
	}
}
