import {buildComponent, Component, ComponentConfig} from './index';
import {DEFAULT_COLORS, getColorString, getSpacingString} from '../util/style-util';
import {ValueType} from '../model/NativeAdObject';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';
import CarouselProgressIndicator, {CarouselProgressIndicatorConfig} from './subcomponents/CarouselProgressIndicator';
import {trackCarouselClick, trackCarouselImpression} from '../../services/trackingService';

export type CarouselConfig = {
	type: 'carousel'
	pageWidth: number
	padding?: Spacing
	backgroundColor?: Color
	pageSpacing?: number
	sidePadding?: number
	indicator?: CarouselProgressIndicatorConfig & { position?: string }
	views: Array<ComponentConfig>
} & BaseComponentConfig;

export default class Carousel extends BaseComponent {
	padding?: Spacing;
	backgroundColor?: Color;
	pageWidth: number;
	pageSpacing?: number;
	sidePadding?: number;
	indicator?: CarouselProgressIndicator;
	indicatorPosition?: string;
	views: Array<Component>;
	slides: Array<Element>;

	#slideTracker: IntersectionObserver;
	#impressionsTracked: Record<number, boolean>;

	constructor(config: CarouselConfig, renderConfig: RenderConfig) {
		super(config);

		this.padding = config.padding;
		this.backgroundColor = config.backgroundColor;
		this.pageWidth = config.pageWidth;
		this.pageSpacing = config.pageSpacing;
		this.sidePadding = config.sidePadding;
		this.views = config.views.map((c) => buildComponent(c, renderConfig));
		this.slides = [];
		this.#impressionsTracked = {};

		if (config.indicator) {
			this.indicator = new CarouselProgressIndicator(config.indicator);
			this.indicatorPosition = config.indicator.position ?? 'top';
		}
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div'),
			scrollContainer = document.createElement('div');

		renderContext.style.style(el, {
			'width': '100%',
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'padding': getSpacingString(this.padding),
		});

		renderContext.style.style(scrollContainer, {
			'display': 'flex',
			'flexDirection': 'row',
			'overflowX': 'auto',
			'width': '100%',
			'scrollSnapType': 'x proximity',
			'pointerEvents': 'all' // For scrolling
		});

		this.slides = [];
		for (let i = 0; i <= renderContext.adObject.valueTypeMaxIdx; i++) {
			renderContext.adObject.valueTypeIndex = i;

			const slideEl = this.#createSlide(renderContext, i);

			scrollContainer.appendChild(slideEl);
			this.slides.push(slideEl);
		}

		el.appendChild(scrollContainer);

		if (this.indicator) {
			const indicatorEL = this.indicator.render(renderContext);

			if (this.indicatorPosition === 'bottom') {
				el.append(indicatorEL);
			} else {
				el.prepend(indicatorEL);
			}

			this.indicator.setProgress(1);
		}

		this.#addEvents(el, renderContext);

		delete renderContext.adObject.valueTypeIndex;

		return el;
	}

	#createSlide(renderContext: RenderContext, index: number): HTMLElement {
		const clickUrl = renderContext.adObject.getValue(ValueType.displayUrlIdx) || renderContext.adObject.getValue(ValueType.clickUrl),
			el = document.createElement('a');

		el.setAttribute('href', clickUrl);
		el.setAttribute('target', '_blank');
		el.setAttribute('rel', 'noopener noreferrer nofollow');
		el.setAttribute('data-carousel-slide', `${index}`);

		renderContext.style.style(el, {
			'display': 'block',
			'flexShrink': '0',
			'flexGrow': '0',
			'boxSizing': 'content-box',
			'width': `${this.pageWidth}px`,
			'scrollSnapAlign': 'start',
			'pointerEvents': 'all' // For click tracking
		});

		// Separate so the above doesn't get duplicated
		renderContext.style.style(el, {
			'paddingLeft': index === 0 ? `${this.sidePadding ?? 0}px` : `${this.pageSpacing ?? 0}px`,
		});

		if (index === renderContext.adObject.valueTypeMaxIdx) {
			renderContext.style.style(el, {
				'paddingRight': `${this.sidePadding ?? 0}px`,
				'scrollSnapAlign': 'end',
			});
		}

		this.views.forEach((component: Component) => {
			el.appendChild(component.render(renderContext));
		});

		return el;
	}

	#addEvents(el: Element, renderContext: RenderContext) {
		this.#addSlideTracker(el, renderContext);
		trackCarouselClick(renderContext, this);
	}

	#addSlideTracker(el: Element, renderContext: RenderContext) {
		const createObserver = () => {
			this.#slideTracker?.disconnect();

			const observer = new IntersectionObserver((entryList) => {
				entryList.forEach((entry) => {
					if (entry.isIntersecting) {
						this.#onSlideInView(renderContext, entry.target);
					}
				});
			}, {
				// 100% of width, but only 1px of height
				'rootMargin': `${el.clientHeight}px 0px`,
				'threshold': [1]
			});

			this.slides.forEach((slide) => {
				observer.observe(slide);
			});

			this.#slideTracker = observer;
		};

		createObserver();

		// Redo intersection observer on resize, as slides might've changed in height
		new ResizeObserver(() => {
			createObserver();
		}).observe(el);
	}

	#onSlideInView(renderContext: RenderContext, slide: Element) {
		const slideIdx = this.slides.indexOf(slide);

		this.indicator?.setProgress(slideIdx + 1);
		if (!this.#impressionsTracked[slideIdx]) {
			trackCarouselImpression(slideIdx, renderContext, this);
			this.#impressionsTracked[slideIdx] = true;
		}
	}
}
